.amplify-authenticator {
    background-color: #f5f5f5;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.amplify-button[data-variation='primary'] {
    background-color: #007bff;
    color: white;
}

/* Input fields */
.amplify-field-group__control {
    border-color: #007bff;
}

.amplify-tabs {
    border-bottom: 2px solid #007bff;
}

.amplify-tabs-item[data-state='active'] {
    color: #007bff;
    border-color: #007bff;
}

.sign-in-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.spinner-container {
    display: flex;
    gap: 10px;
}

.loading-text {
    margin-top: 20px;
    font-size: 18px;
    color: #007bff;
}