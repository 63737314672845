.services-container {
    padding: 2rem 0;
}

.services-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #333;
}

.loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.loading-spinner .spinner-grow {
    margin: 0 0.5rem;
}

.services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

@media (max-width: 768px) {
    .services-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
}