.order-container {
    max-width: 1000px;
    margin: 2rem auto;
    padding: 0 1rem;
}

.order-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
}

.order-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.order-card h2 {
    background-color: #3a86ff;
    color: #fff;
    padding: 1.5rem;
    margin: 0;
    font-size: 1.5rem;
    font-weight: 600;
}

.order-card-body {
    padding: 1.5rem;
}

.empty-card {
    text-align: center;
    padding: 3rem;
}

.empty-card p {
    color: #666;
    margin-bottom: 1.5rem;
}

.order-btn {
    background-color: #3a86ff;
    color: #fff;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 600;
    transition: background-color 0.3s ease;
}

.order-btn:hover {
    background-color: #2271ef;
}

.spinner-container {
    display: flex;
    justify-content: center;
    align-items: start;
    height: 100vh;
}

.spinner {
    border: 4px solid rgba(74, 144, 226, 0.3);
    border-left-color: #3a86ff;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-top: 100px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px) {
    .order-card h2 {
        font-size: 1.25rem;
        padding: 1.25rem;
    }

    .order-card-body {
        padding: 1rem;
    }

    .empty-card {
        padding: 2rem;
    }
}