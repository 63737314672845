.header-navbar {
  background-color: #2c3e50;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 0.75rem 1rem;
  position: relative;
}

.header-navbar .navbar-toggler {
  border: none;
  padding: 0.25rem 0.75rem;
}

.header-navbar .hamburger-icon {
  color: #ecf0f1;
  font-size: 1.8rem;
}

.header-navbar > .container {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.navbar-nav .nav-link {
  color: #ecf0f1 !important;
  transition: color 0.3s ease, transform 0.2s ease;
  font-size: 18px;
  font-weight: 500;
}

.navbar-nav .nav-link:hover,
.navbar-nav .nav-link.active {
  color: #3498db !important;
  transform: translateY(-2px);
}

.navbar-nav.ms-auto {
  margin-left: auto !important;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(236, 240, 241, 0.9)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
  border-color: #ecf0f1;
}

.brand {
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, filter 0.3s ease;
  margin-left: 0;
}

.brand:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

.brand-icon {
  color: #3498db;
  font-size: 2rem;
  margin-right: 0.5rem;
}

.brand-name {
  font-weight: 700;
  font-size: 1.5rem;
  color: #ecf0f1;
  letter-spacing: 0.5px;
}

.header-navbar .search-input {
  width: 100%;
  max-width: 320px;
  margin-right: 1rem;
  border: 1px solid #34495e;
  border-radius: 30px;
  padding: 0.6rem 1.2rem;
  background-color: #34495e;
  color: #ecf0f1;
  transition: all 0.3s ease;
}

.search-input:focus {
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.5);
  background-color: #2c3e50;
}

.btn-outline-danger,
.btn-outline-success {
  border-width: 2px;
  font-weight: 600;
  padding: 8px 20px !important;
  border-radius: 25px !important;
  transition: all 0.3s ease;
  margin-right: 10px;
  color: #ffffff !important;
}

.btn-outline-success {
  background-color: #2ecc71 !important;
  border-color: #2ecc71 !important;
}

.btn-outline-success:hover {
  background-color: #27ae60 !important;
  border-color: #27ae60 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.btn-outline-danger {
  background-color: #e74c3c !important;
  border-color: #e74c3c !important;
}

.btn-outline-danger:hover {
  background-color: #c0392b !important;
  border-color: #c0392b !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.cart-dropdown {
  position: relative;
}

.cart-dropdown .dropdown-toggle {
  background-color: #3498db;
  border-color: #3498db;
  color: #ffffff;
  padding: 0.6rem 1.2rem;
  border-radius: 25px;
  transition: all 0.3s ease;
  font-weight: 600;
}

.cart-dropdown .dropdown-toggle:hover,
.cart-dropdown .dropdown-toggle:focus {
  background-color: #2980b9;
  border-color: #2980b9;
  box-shadow: 0 0 0 0.25rem rgba(52, 152, 219, 0.5);
}

.shopping-cart-class{
  color: white;
}

.cart-dropdown .dropdown-menu {
  right: 0;
  left: auto;
  transform: none !important;
  margin-top: 0.5rem;
}

.cart-dropdown .dropdown-menu::before {
  content: '';
  position: absolute;
  top: -10px;
  right: 10px;
  border-width: 0 10px 10px 10px;
  border-style: solid;
  border-color: transparent transparent #ffffff transparent;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-bottom: 1px solid #e9ecef;
  transition: background-color 0.2s ease;
}

.cart-item:last-child {
  border-bottom: none;
}

.cart-item:hover {
  background-color: #ecf0f1;
}

.cart-item-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.cart-item-name {
  font-weight: 600;
  font-size: 1rem;
  color: #333;
  margin-right: 0.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.cart-item-price {
  color: #2ecc71;
  font-size: 0.9rem;
  font-weight: 700;
}

.cart-item-delete {
  cursor: pointer;
  color: #e74c3c;
  font-size: 1.3rem;
  transition: color 0.2s, transform 0.2s;
}

.cart-item-delete:hover {
  color: #c0392b;
  transform: scale(1.15);
}

@media (max-width: 992px) {
  .header-navbar .search-input {
    margin-bottom: 1rem;
    width: 60%;
    max-width: 100%;
  }
  .navbar-nav.ms-auto {
    margin-left: 0 !important;
  }
  .navbar-nav .nav-link {
    font-size: 16px;
  }
  .cart-dropdown .dropdown-menu {
    position: absolute;
    width: 90vw;
    max-width: 320px;
  }
}

@media (max-width: 576px) {
  .brand-icon {
    font-size: 1.5rem;
  }

  .brand-name {
    font-size: 1.1rem;
  }
}