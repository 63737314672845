.service-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
    height: 100%;
}

.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.service-image-container {
    position: relative;
    padding-top: 75%;
    overflow: hidden;
}

.service-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.service-card:hover .service-image {
    transform: scale(1.05);
}

.service-title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 0;
    color: #333;
}