.filters {
    background-color: white;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.filters-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #333;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-check {
    margin-bottom: 0.5rem;
}

.rating-filter {
    margin-top: 0.5rem;
}

.clear-filters-btn {
    width: 100%;
    margin-top: 1rem;
}