.pro-modal .modal-content {
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.pro-modal .modal-header {
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;
    padding: 1rem 1.5rem;
}

.pro-modal .modal-title {
    color: #2c3e50;
    font-weight: 600;
    font-size: 1.25rem;
}

.pro-modal .modal-header .close {
    font-size: 1.5rem;
    color: #6c757d;
    opacity: 1;
    transition: color 0.2s ease, transform 0.2s ease;
    padding: 0.5rem;
    margin: -0.5rem -0.5rem -0.5rem auto;
}

.pro-modal .modal-header .close:hover {
    color: #343a40;
    transform: scale(1.1);
}

.pro-modal .modal-header .close span {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1;
}

.pro-card {
    padding: 1rem;
}

.pro-image {
    text-align: center;
    margin-bottom: 1rem;
}

.pro-image img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border: 3px solid #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.pro-info p {
    margin-bottom: 0.75rem;
    color: #34495e;
    font-size: 0.95rem;
    line-height: 1.4;
}

.pro-info p strong {
    color: #2c3e50;
    font-weight: 600;
}

.pro-rating {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-top: 0.75rem;
}

.pro-rating strong {
    color: #2c3e50;
    font-weight: 600;
}

.pro-modal .modal-footer {
    border-top: 1px solid #e9ecef;
    padding: 1rem 1.5rem;
}

.pro-modal .btn-secondary {
    background-color: #3498db;
    border-color: #3498db;
    color: #ffffff;
    font-weight: 500;
    padding: 0.4rem 1.2rem;
    font-size: 0.95rem;
    transition: background-color 0.2s ease;
}

.pro-modal .btn-secondary:hover {
    background-color: #2980b9;
    border-color: #2980b9;
}