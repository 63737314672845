.home {
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 2rem;
}

.home-container {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    gap: 2rem;
}

.filters-sidebar {
    flex: 0 0 250px;
}

.products-main {
    flex: 1;
}

.product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

@media (max-width: 992px) {
    .home-container {
        flex-direction: column;
    }

    .filters-sidebar {
        flex: 0 0 auto;
    }
    .product-grid {
        grid-template-columns: repeat(auto-fill, minmax(170px, 1fr));
    }
}

@media (max-width: 768px) {
    .home-container {
        flex-direction: column;
    }

    .filters-sidebar {
        flex: 0 0 auto;
    }
    .product-grid {
        grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    }
}
@media (max-width: 576px) {
    .product-grid {
        grid-template-columns: repeat(auto-fill, minmax(153px, 1fr));
    }
}