.chat-button {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #8e44ad;
  color: white;
  border: none;
  padding: 15px 25px;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(142, 68, 173, 0.4);
  transition: all 0.3s ease;
}

.chat-button span {
  margin-left: 12px;
}

@keyframes blink {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.2); opacity: 0.7; }
  100% { transform: scale(1); opacity: 1; }
}

.chat-button i {
  animation: blink 2s infinite;
  margin-right: 10px;
  background: white;
}

.chat-button:hover {
  background-color: #9b59b6;
  transform: translateY(-3px);
  box-shadow: 0 6px 20px rgba(142, 68, 173, 0.6);
}

